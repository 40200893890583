import React, { useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import config from '../config';

const FeedbackPage = ({ user }) => {
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const idToken = await user.getIdToken();
      await axios.post(`${config.API_BASE_URL}/feedback`, { feedback }, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      setSubmitStatus('success');
      setFeedback('');
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-12 p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-light text-purple-600 mb-6">We'd Love to Hear from You!</h1>
      <p className="text-gray-600 mb-6">
        Hey there! 👋 We hope you're enjoying loomos. Your feedback is incredibly valuable to us and helps make the product even better. Got any thoughts, ideas, or suggestions? We're all ears!
      </p>
      <form onSubmit={handleSubmit} className="space-y-4">
        <textarea
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder="Tell us what you think! The good, the bad, we want it all..."
          className="w-full h-40 p-3 border border-gray-300 rounded-lg focus:ring-purple-500 focus:border-purple-500"
          required
        />
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          type="submit"
          disabled={isSubmitting}
          className="w-full py-2 px-4 bg-purple-600 text-white rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 disabled:opacity-50"
        >
          {isSubmitting ? 'Sending...' : 'Send Feedback'}
        </motion.button>
      </form>
      {submitStatus === 'success' && (
        <p className="mt-4 text-green-600">Thank you for your feedback! We really appreciate it. 🎉</p>
      )}
      {submitStatus === 'error' && (
        <p className="mt-4 text-red-600">Oops! Something went wrong. Please try again later.</p>
      )}
      <div className="mt-8 text-gray-600">
        <p>Your input helps shape the future of loomos. Together, we're creating something amazing!</p>
        <p className="mt-2">Thank you for being part of our journey. 💜</p>
      </div>
    </div>
  );
};

export default FeedbackPage;