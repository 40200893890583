import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthProvider';
import LibraryView from './components/LibraryView';
import SidePanel from './components/SidePanel';
import LandingPage from './components/LandingPage';
import SharedVideoPage from './components/SharedVideoPage';
import NewProject from './components/NewProject';
import TutorialPage from './components/TutorialPage'; // Add this import
import FeedbackPage from './components/FeedbackPage'; // Add this import

const VideoProcessingApp = () => {
  const auth = useAuth();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);

  const closeSidePanel = () => {
    setIsSidePanelOpen(false);
  };

  const openSidePanel = () => {
    setIsSidePanelOpen(true);
  };

  const handleLogout = () => {
    // Implement your logout logic here
    // For example: auth.signOut();
  };

  return (
    <AuthProvider>
      <Router>
        <div className="flex bg-gradient-to-br from-indigo-50 to-purple-50 min-h-screen font-circular">
          {auth && auth.user && isSidePanelOpen && <SidePanel onLogout={handleLogout} />}
          <div className="flex-grow overflow-y-auto">
            <Routes>
              <Route path="/shared/:videoId" element={<SharedVideoPage />} />
              <Route 
                path="/" 
                element={
                  auth && auth.user ? (
                    <NewProject user={auth.user} closeSidePanel={closeSidePanel} openSidePanel={openSidePanel} />
                  ) : (
                    <Navigate to="/login" />
                  )
                } 
              />
              <Route 
                path="/library" 
                element={
                  auth && auth.user ? (
                    <LibraryView user={auth.user} closeSidePanel={closeSidePanel} />
                  ) : (
                    <Navigate to="/login" />
                  )
                } 
              />
              <Route 
                path="/login" 
                element={
                  auth && auth.user ? (
                    <Navigate to="/" />
                  ) : (
                    <LandingPage />
                  )
                } 
              />
              <Route 
                path="/tutorial" 
                element={<TutorialPage />} 
              />
              <Route 
                path="/feedback" 
                element={<FeedbackPage user={auth.user} />} 
              />
            </Routes>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default VideoProcessingApp;
