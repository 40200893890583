import React, { useState, useEffect, useRef } from 'react';
import { ChevronRight, Globe, Mic, Image, ArrowRight, Play, Pause, Edit, Palette, Maximize2, Minimize2 } from 'lucide-react';
import { useAuth } from '../AuthProvider';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const MagicWandIcon = ({ className = '', size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-10 -10 120 120"
    width={size}
    height={size}
    className={className}
  >
    <g transform="rotate(90, 50, 50)">
      <path
        d="M50 15 L50 85 M15 50 L85 50 M25 25 L75 75 M75 25 L25 75"
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M75 75 L95 95"
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

const LandingPage = () => {
  const { handleGoogleSignIn } = useAuth();
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [isBeforePlaying, setIsBeforePlaying] = useState(false);
  const [isAfterPlaying, setIsAfterPlaying] = useState(false);
  const beforeVideoRef = useRef(null);
  const afterVideoRef = useRef(null);
  const [activeVoice, setActiveVoice] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(0);

  const [currentUseCase, setCurrentUseCase] = useState(0);
  const useCases = [
    "Product Demos",
    "Training Videos",
    "Tutorial Walkthroughs",
    "Software Showcases",
    "Onboarding Guides",
    "Feature Explanations",
  ];

  const [isWiderView, setIsWiderView] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // Update font loading
    const link = document.createElement('link');
    link.href = 'https://fonts.cdnfonts.com/css/circular-std';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    // Apply font to body
    document.body.style.fontFamily = "'Circular Std', Helvetica, sans-serif";

    // Cleanup
    return () => {
      document.head.removeChild(link);
      document.body.style.fontFamily = '';
    };
  }, []);

  useEffect(() => {
    console.log("Before video ref:", beforeVideoRef.current);
    console.log("After video ref:", afterVideoRef.current);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentUseCase((prev) => (prev + 1) % useCases.length);
    }, 3000); // Change text every 3 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSignIn = async () => {
    try {
      await handleGoogleSignIn();
      navigate('/');
    } catch (error) {
      console.error('Error signing in:', error);
    }
  };

  const toggleBeforeVideo = () => {
    if (beforeVideoRef.current) {
      if (isBeforePlaying) {
        beforeVideoRef.current.pause();
      } else {
        // Stop the after video if it's playing
        if (isAfterPlaying && afterVideoRef.current) {
          afterVideoRef.current.pause();
          setIsAfterPlaying(false);
        }
        beforeVideoRef.current.play();
      }
      setIsBeforePlaying(!isBeforePlaying);
    }
  };

  const toggleAfterVideo = () => {
    if (afterVideoRef.current) {
      if (isAfterPlaying) {
        afterVideoRef.current.pause();
      } else {
        // Stop the before video if it's playing
        if (isBeforePlaying && beforeVideoRef.current) {
          beforeVideoRef.current.pause();
          setIsBeforePlaying(false);
        }
        afterVideoRef.current.play();
      }
      setIsAfterPlaying(!isAfterPlaying);
    }
  };

  const handleVideoError = (e, videoType) => {
    console.error(`Error playing ${videoType} video:`, e.target.error);
  };

  const playBeforeVideo = () => {
    console.log("Attempting to play before video");
    if (beforeVideoRef.current) {
      beforeVideoRef.current.play()
        .then(() => console.log("Before video playing"))
        .catch(e => console.error("Error playing before video:", e));
    } else {
      console.error("Before video ref is null");
    }
  };

  const playAfterVideo = (langCode) => {
    console.log(`Attempting to play ${langCode} after video`);
    if (afterVideoRef.current) {
      afterVideoRef.current.src = `${process.env.PUBLIC_URL}/${langCode}-after-video.mp4`;
      afterVideoRef.current.play()
        .then(() => {
          console.log(`${langCode} after video playing`);
          setIsAfterPlaying(true);
        })
        .catch(e => console.error(`Error playing ${langCode} after video:`, e));
    } else {
      console.error("After video ref is null");
    }
  };

  const playVoiceSample = (voiceId) => {
    setActiveVoice(voiceId);
    // Logic to play voice sample
    console.log(`Playing voice sample: ${voiceId}`);
  };

  const languages = [
    { code: 'en', name: 'English', flag: '🇺🇸' },
    { code: 'es', name: 'Spanish', flag: '🇪🇸' },
    { code: 'hi', name: 'Hindi', flag: '🇮🇳' },
    { code: 'gn', name: 'German', flag: '🇩🇪' },
  ];

  const features = [
    {
      icon: Edit,
      title: "AI-Powered Transcript Editing",
      desc: "Edit your transcript yourself, or use AI to generate an improved version automatically. Our AI cleans up 'uhms' and improves grammar for a polished result.",
      media: process.env.PUBLIC_URL + "transcript.mp4"
    },
    {
      icon: Image,
      title: "Aesthetic Background Images",
      desc: "Enhance your videos with beautiful background images to create a polished look.",
      media: process.env.PUBLIC_URL + "bg.gif"
    },
    {
      icon: Globe,
      title: "Multilingual Translation & AI Voiceovers",
      desc: "Translate to multiple languages and select from a variety of AI voiceovers that sound professional yet human-like, with different accents available.",
      media: process.env.PUBLIC_URL + "voice.gif"
    },
    {
      icon: Palette,
      title: "Custom Branding",
      desc: "Add your unique touch with custom branding elements, logos, and color schemes.",
      media: "/images/custom-branding.jpg"
    },
  ];

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  const staggerChildren = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } }
  };

  const toggleWiderView = () => {
    setIsWiderView(!isWiderView);
  };

  return (
    <div className="bg-white text-gray-900 min-h-screen overflow-x-hidden font-['Circular_Std',Helvetica,sans-serif]">
      {/* Navigation */}
      <motion.nav 
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 100 }}
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
          isScrolled ? 'bg-white/80 backdrop-blur-md shadow-md' : 'bg-transparent'
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className={`flex justify-between items-center transition-all duration-300 ${
            isScrolled ? 'py-4' : 'py-8'
          }`}>
            <div className="flex-shrink-0 flex items-center">
              <MagicWandIcon size={isScrolled ? 36 : 48} className="text-purple-600" />
              <h1 className={`font-extrabold bg-gradient-to-r from-purple-600 via-indigo-600 to-blue-500 text-transparent bg-clip-text transition-all duration-300 ${
                isScrolled ? 'text-2xl' : 'text-3xl'
              }`} style={{ textShadow: '0 0 1px rgba(0,0,0,0.1)' }}>
                loomos
              </h1>
            </div>
            <button
              onClick={handleSignIn}
              className={`bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded-full font-semibold transition duration-300 hover:from-purple-700 hover:to-indigo-700 ${
                isScrolled ? 'px-4 py-2 text-sm' : 'px-6 py-3 text-base'
              }`}
            >
              Start Free Trial
            </button>
          </div>
        </div>
      </motion.nav>

      {/* Add padding to the top of the first section to account for the fixed navbar */}
      <div className={`transition-all duration-300 ${isScrolled ? 'pt-20' : 'pt-32'}`}></div>

      {/* Hero Section */}
      <section className="py-32 bg-[hsl(240,70%,89%)] overflow-hidden">
        <motion.div 
          initial="hidden"
          animate="visible"
          variants={staggerChildren}
          className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative"
        >
          <motion.h2 
            variants={fadeIn} 
            className="text-6xl font-bold mb-6 leading-tight tracking-tight"
          >
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-900">
              Studio-Grade
            </span>
            <br />
            <span className="h-20 inline-block overflow-hidden mt-4">
              <AnimatePresence mode="wait">
                <motion.span
                  key={currentUseCase}
                  initial={{ y: 50, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -50, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className="block bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-indigo-600"
                >
                  {useCases[currentUseCase]}
                </motion.span>
              </AnimatePresence>
            </span>
            <br />
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-900">
              In Minutes.
            </span>
          </motion.h2>
          <motion.p variants={fadeIn} className="text-xl mb-10 text-[hsl(251,32%,36%)] max-w-2xl mx-auto font-light">
            Transform rough screen recordings into polished videos in minutes. No editing skills required.
          </motion.p>
          <motion.button
            variants={fadeIn}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleSignIn}
            className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:from-purple-700 hover:to-indigo-700 transition duration-300 inline-flex items-center"
          >
            Start Your Free Trial
            <ArrowRight className="ml-2" size={20} />
          </motion.button>
        </motion.div>
      </section>
      
      {/* Video Comparison */}
      <section className="py-32 bg-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h3 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-4xl font-bold text-center mb-8"
          >
            Experience the Transformation
          </motion.h3>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-xl text-center mb-16 text-gray-600 max-w-3xl mx-auto"
          >
            Witness how Loomos elevates your content in just minutes, turning rough recordings into polished, professional videos.
          </motion.p>
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="relative"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-indigo-600 rounded-3xl transform -rotate-1"></div>
            <div className="relative bg-white rounded-3xl shadow-2xl overflow-hidden">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-8">
                <div className="space-y-4">
                  <h4 className="text-2xl font-semibold text-gray-800">Before</h4>
                  <div className="aspect-video rounded-2xl overflow-hidden shadow-lg">
                    <video 
                      ref={beforeVideoRef}
                      className="w-full h-full object-cover" 
                      poster={`${process.env.PUBLIC_URL}/before-thumbnail.jpg`}
                      controls
                    >
                      <source src={process.env.PUBLIC_URL + "/before-video.mp4"} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
                <div className="space-y-4">
                  <h4 className="text-2xl font-semibold text-gray-800">After</h4>
                  <div className="aspect-video rounded-2xl overflow-hidden shadow-lg">
                    <video 
                      ref={afterVideoRef}
                      className="w-full h-full object-cover" 
                      poster={`${process.env.PUBLIC_URL}/${selectedLanguage}-after-thumbnail.jpg`}
                      controls
                    >
                      <source src={`${process.env.PUBLIC_URL}/${selectedLanguage}-after-video.mp4`} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className="flex flex-wrap justify-center gap-4 mt-6">
                    {languages.map((lang) => (
                      <button
                        key={lang.code}
                        onClick={() => {
                          setSelectedLanguage(lang.code);
                          playAfterVideo(lang.code);
                        }}
                        className={`px-6 py-3 rounded-full text-sm font-medium transition duration-300 ${
                          selectedLanguage === lang.code 
                            ? 'bg-gradient-to-r from-purple-600 to-indigo-600 text-white shadow-md' 
                            : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                        }`}
                      >
                        <span className="mr-2">{lang.flag}</span>
                        {lang.name}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="text-center mt-16"
          >
            <p className="text-lg text-gray-600 mb-8">
            Studio-style videos from simple screen recordings.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-32 bg-[hsl(240,70%,89%)]">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h3 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-4xl font-bold text-center mb-6"
          >
            Create Professional Product Videos Effortlessly
          </motion.h3>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-xl text-center mb-16 text-gray-600"
          >
            No editing skills required. Our AI-powered tools do the heavy lifting for you.
          </motion.p>
          <motion.div 
            variants={staggerChildren}
            initial="hidden"
            whileInView="visible"
            className="space-y-24"
          >
            {features.map((feature, index) => (
              <motion.div 
                key={index} 
                variants={fadeIn}
                className={`flex flex-col md:flex-row items-center ${
                  index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
                }`}
              >
                <div className={`w-full md:w-1/2 ${
                  index % 2 === 0 ? 'md:pr-12' : 'md:pl-12'
                }`}>
                  <feature.icon className="w-12 h-12 text-indigo-600 mb-4" />
                  <h4 className="text-2xl font-semibold mb-4">{feature.title}</h4>
                  <p className="text-gray-600">{feature.desc}</p>
                </div>
                <div className="w-full md:w-1/2 mt-8 md:mt-0">
                  <div className="rounded-lg overflow-hidden shadow-lg">
                    {feature.media.endsWith('.gif') ? (
                      <img 
                        src={feature.media} 
                        alt={feature.title} 
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <video 
                        src={feature.media} 
                        autoPlay 
                        muted 
                        loop 
                        className="w-full h-full object-cover"
                      />
                    )}
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* Loom Integration Highlight */}
      <section className="py-20 bg-gradient-to-r from-purple-100 to-indigo-100">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h3 className="text-3xl font-bold mb-6">Elevate Your Loom Recordings</h3>
            <p className="text-xl mb-8 text-gray-700">
              Transform your Loom screen recordings into polished, professional videos with just a few clicks. Seamlessly import and enhance your content.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleSignIn}
              className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:from-purple-700 hover:to-indigo-700 transition duration-300"
            >
              Upgrade Your Loom Videos
            </motion.button>
          </motion.div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="py-32 bg-gray-50">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h3 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-4xl font-bold text-center mb-16"
          >
            Effortless Video Creation in Three Steps
          </motion.h3>
          <motion.div 
            variants={staggerChildren}
            initial="hidden"
            whileInView="visible"
            className="space-y-12"
          >
            {[
              { step: 1, title: "Upload Your Own Video or Provide a Loom Recording Link", desc: "Choose your preferred method to bring your content to life" },
              { step: 2, title: "Select Voice & Edit Transcript", desc: "Choose the voice and edit the transcript with cleaned up 'uhms' and improved grammar" },
              { step: 3, title: "Download & Share", desc: "Get your polished video with professional, human-like AI voiceovers, then download and share" },
            ].map((item, index) => (
              <motion.div key={index} variants={fadeIn} className="flex items-center">
                <div className="flex-shrink-0 mr-8">
                  <div className="w-16 h-16 rounded-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white flex items-center justify-center text-2xl font-bold">
                    {item.step}
                  </div>
                </div>
                <div>
                  <h4 className="text-2xl font-semibold mb-2">{item.title}</h4>
                  <p className="text-gray-600">{item.desc}</p>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-32 bg-gradient-to-r from-gray-900 to-gray-800 text-white">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center"
        >
          <h3 className="text-5xl font-bold mb-6">Revolutionize Your Video Communication</h3>
          <p className="text-xl mb-10 text-gray-300 max-w-2xl mx-auto">Join thousands of professionals creating impactful videos with ease. Start your journey today.</p>
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleSignIn} 
            className="bg-white text-gray-900 px-10 py-4 rounded-full text-xl font-bold hover:bg-gray-100 transition duration-300"
          >
            Get Loomos for Free
          </motion.button>
        </motion.div>
      </section>

      {/* Footer */}
      <footer className="bg-white text-gray-600">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center mb-4 md:mb-0">
              <MagicWandIcon size={24} className="text-indigo-600 mr-2" />
              <span className="text-xl font-semibold">Loomos</span>
            </div>
            <div className="flex space-x-6">
              <a href="#" className="hover:text-gray-900 transition duration-300">Privacy</a>
              <a href="#" className="hover:text-gray-900 transition duration-300">Terms</a>
              <a href="#" className="hover:text-gray-900 transition duration-300">Contact</a>
            </div>
          </div>
          <div className="mt-8 text-center">
            <p className="text-sm">&copy; 2024 Loomos. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;