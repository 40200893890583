import React from 'react';
import { motion } from 'framer-motion';
import { Rocket, RefreshCw, AlertCircle } from 'lucide-react';
import axios from 'axios';
import config from '../config';

const PublishButton = ({ user, uploadedVideoId, selectedLanguage, selectedVoice, transcript, projectTitle, backgroundImage, setDubbedVideoId, publishingStatus, setPublishingStatus }) => {
  const handlePublish = async () => {
    try {
      setPublishingStatus('processing');
      const idToken = await user.getIdToken();
      
      // First, make a video dub async request
      const dubResponse = await axios.post(`${config.API_BASE_URL}/video-dub-async`, {
        video_id: uploadedVideoId,
        modifier: {
          language: { id: parseInt(selectedLanguage) },
          voice_id: selectedVoice,
          transcript: transcript.map((paragraph) => ({
            start: paragraph.start,
            end: paragraph.end,
            text: paragraph.text.trim(),
          })),
        },
        title: projectTitle,
        background_image_id: backgroundImage,
      }, {
        headers: { 'Authorization': `Bearer ${idToken}` }
      });

      const newDubbedVideoId = dubResponse.data.video_id;
      setDubbedVideoId(newDubbedVideoId);

      // Check processing status until completed
      await checkProcessingStatus(newDubbedVideoId, idToken);

      // Once processing is complete, save the video
      const saveResponse = await axios.post(`${config.API_BASE_URL}/save-video/${newDubbedVideoId}`, {
        title: projectTitle,  // Add the project title to the save request
      }, {
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
      
      if (saveResponse.status === 200) {
        console.log('Video published successfully');
        setPublishingStatus('completed');
        setTimeout(() => setPublishingStatus('idle'), 5000); // Reset after 5 seconds
      } else {
        throw new Error('Failed to publish video');
      }
    } catch (error) {
      console.error('Error publishing video:', error);
      setPublishingStatus('error');  // Change to 'error' instead of 'idle'
    }
  };

  const checkProcessingStatus = async (videoId, idToken) => {
    try {
      while (true) {  // Use a while loop for better control
        const response = await axios.get(`${config.API_BASE_URL}/is-processed/${videoId}`, {
          headers: { 'Authorization': `Bearer ${idToken}` }
        });
        if (response.data.is_processed) {
          console.log(`Processing completed for video ID: ${videoId}`);
          return;
        }
        console.log(`Processing still in progress for video ID: ${videoId}`);
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    } catch (error) {
      console.error('Error checking processing status:', error);
      throw error;  // Rethrow the error to be caught in handlePublish
    }
  };

  return (
    <motion.button
      className={`flex items-center space-x-2 bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 text-lg ${
        publishingStatus === 'processing' ? 'opacity-50 cursor-not-allowed' : ''
      } ${publishingStatus === 'error' ? 'bg-red-500' : ''}`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={handlePublish}
      disabled={publishingStatus === 'processing'}
    >
      <span className="font-medium flex items-center">
        {publishingStatus === 'processing' ? (
          <>
            <RefreshCw size={20} className="mr-2 animate-spin" />
            Saving...
          </>
        ) : publishingStatus === 'error' ? (
          <>
            <AlertCircle size={20} className="mr-2" />
            Error
          </>
        ) : (
          <>
            <Rocket size={20} className="mr-2" />
            Save
          </>
        )}
      </span>
    </motion.button>
  );
};

export default PublishButton;