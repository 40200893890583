import React from 'react';
import { PlusCircle, Library, LogOut, PlayCircle, MessageSquare } from 'lucide-react';
import { motion } from 'framer-motion';
import { useAuth } from '../AuthProvider';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const MagicWandIcon = ({ className = '', size = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-10 -10 120 120"
    width={size}
    height={size}
    className={className}
  >
    <g transform="rotate(90, 50, 50)">
      <path
        d="M50 15 L50 85 M15 50 L85 50 M25 25 L75 75 M75 25 L25 75"
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
      />
      <path
        d="M75 75 L95 95"
        stroke="currentColor"
        strokeWidth="10"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

const SidePanel = () => {
  const { user, handleGoogleSignIn, handleSignOut } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Implement your logout logic here
    // For example: auth.signOut();
    handleSignOut();
    navigate('/login');
  };

  return (
    <div className="w-64 bg-white shadow-lg text-gray-800 h-screen p-6 space-y-6">
      <h1 className="text-3xl font-bold mb-8 flex items-center">
        <MagicWandIcon size={42} className="text-purple-600 mr-2" />
        <span className="bg-gradient-to-r from-purple-600 via-indigo-600 to-blue-500 text-transparent bg-clip-text">
          loomos
        </span>
      </h1>
      <nav className="space-y-2">
        {[
          { name: 'New Project', icon: PlusCircle, path: '/' },
          { name: 'My Library', icon: Library, path: '/library' },
          { name: 'Tutorial', icon: PlayCircle, path: '/tutorial' },
          { name: 'Feedback', icon: MessageSquare, path: '/feedback' }, // Add this line
        ].map((item) => (
          <Link
            key={item.name}
            to={item.path}
            className={`flex items-center space-x-3 w-full p-3 rounded-lg ${
              location.pathname === item.path 
                ? 'bg-gradient-to-r from-purple-100 to-indigo-100 text-indigo-700' 
                : 'hover:bg-gray-100'
            }`}
          >
            <motion.div
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.98 }}
              className="flex items-center space-x-3 w-full"
            >
              <item.icon size={20} />
              <span className="font-medium">{item.name}</span>
            </motion.div>
          </Link>
        ))}
      </nav>
      <div className="flex-grow" />
      {user ? (
        <motion.button
          className="flex items-center space-x-3 w-full p-3 rounded-lg hover:bg-gray-100 text-gray-700"
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleLogout}
        >
          <LogOut size={20} />
          <span className="font-medium">Log Out</span>
        </motion.button>
      ) : (
        <motion.button
          className="flex items-center space-x-3 w-full p-3 rounded-lg bg-gradient-to-r from-purple-600 to-indigo-600 text-white"
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.98 }}
          onClick={handleGoogleSignIn}
        >
          <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google" className="w-5 h-5" />
          <span className="font-medium">Sign in with Google</span>
        </motion.button>
      )}
    </div>
  );
};

export default SidePanel;