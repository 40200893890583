import React from 'react';
import { Upload, RefreshCw, Loader } from 'lucide-react';

const Step1FileUpload = ({ handleFileUpload, isUploading, uploadProgress, uploadedVideoId, handleCancelUpload, user }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  return (
    <div>
      <div className="bg-white rounded-lg shadow-sm p-8 max-w-6xl mx-auto">
        {!uploadedVideoId ? (
          <div className="flex items-center justify-center w-full">
            <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition duration-300 ease-in-out">
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <Upload className="w-10 h-10 mb-3 text-gray-400" />
                <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                <p className="text-xs text-gray-500">MP4, WebM or OGG (MAX. 800MB)</p>
              </div>
              <input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} accept="video/*" />
            </label>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <button L
              className="bg-purple-100 text-purple-700 py-2 px-4 rounded-md flex items-center transition duration-300 ease-in-out hover:bg-purple-200 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
              onClick={handleCancelUpload}
            >
              <RefreshCw className="mr-2 h-4 w-4" />
              Select Another Video
            </button>
          </div>
        )}
        {isUploading && (
          <div className="mt-6 flex flex-col items-center justify-center">
            <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
              <div className="bg-purple-600 h-2.5 rounded-full" style={{ width: `${uploadProgress}%` }}></div>
            </div>
            <div className="flex items-center">
              <Loader className="animate-spin mr-2 h-5 w-5 text-purple-600" />
              <p className="text-purple-600 font-medium">Uploading... {Math.round(uploadProgress)}%</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Step1FileUpload;