import React, { useState, useEffect, useCallback } from 'react';
import { Check, Upload, X } from 'lucide-react';
import axios from 'axios';
import config from '../config';

const BackgroundsTab = ({ user, setBackgroundImage, backgroundImage, images, setImages, fetchImages, onConfigChange, imageCache, imagesLoading }) => {
  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const uploadImage = async (file) => {
    try {
      const idToken = await user.getIdToken();
      const formData = new FormData();
      formData.append('image', file);

      const response = await axios.post(`${config.API_BASE_URL}/upload-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${idToken}`
        }
      });

      if (response.status === 200) {
        const uploadedImageId = response.data.image_id;
        setBackgroundImage(uploadedImageId);
        await fetchImages(); // Fetch updated image list
        onConfigChange(); // Notify that configuration has changed
        console.log('Image uploaded successfully', response);
      } else {
        console.error('Failed to upload image');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const ImageTile = ({ imageId, isSelected, onClick }) => (
    <div 
      className={`relative cursor-pointer rounded-lg overflow-hidden transition-all duration-300 transform hover:scale-105 ${isSelected ? 'ring-2 ring-purple-500' : ''}`}
      onClick={onClick}
    >
      {imageCache.get(imageId) ? (
        <img
          src={imageCache.get(imageId)}
          alt={`Background ${imageId}`}
          className="w-full h-20 object-cover"
          onError={(e) => {
            console.error(`Failed to load image: ${imageId}`);
          }}
        />
      ) : (
        <div className="w-full h-20 bg-gray-200 flex items-center justify-center">
          <span className="text-gray-400">Loading...</span>
        </div>
      )}
      {isSelected && (
        <div className="absolute inset-0 bg-purple-500 bg-opacity-30 flex items-center justify-center">
          <Check className="text-white h-4 w-4" />
        </div>
      )}
    </div>
  );

  const handleBackgroundSelect = (imageId) => {
    setBackgroundImage(imageId);
    onConfigChange();
  };

  console.log('Current backgroundImage:', backgroundImage);

  const isNoBackgroundSelected = backgroundImage === null || backgroundImage === '' || backgroundImage === undefined;

  return (
    <div className="h-full overflow-auto rounded-lg p-4 bg-gray-50" style={{ height: 'calc(100vh - 16rem)' }}>
      <h3 className="text-lg font-semibold text-gray-800 mb-4">Background Images</h3>
      <div className="flex items-center space-x-2 mb-4">
        <button
          className={`flex-1 py-2 px-4 rounded-lg flex items-center justify-center transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50 ${
            isNoBackgroundSelected
              ? 'bg-purple-100 text-purple-700 hover:bg-purple-200'
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
          onClick={() => {
            setBackgroundImage(null);
            onConfigChange();
          }}
        >
          <X className="mr-2 h-4 w-4" />
          <span>No Background</span>
        </button>
        <div className="flex-1">
          <input
            type="file"
            accept="image/*"
            className="hidden"
            id="image-upload"
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                uploadImage(e.target.files[0]);
              }
            }}
          />
          <label
            htmlFor="image-upload"
            className="flex items-center justify-center w-full py-2 px-4 bg-indigo-100 text-indigo-700 rounded-lg cursor-pointer transition duration-300 ease-in-out hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
          >
            <Upload className="mr-2 h-4 w-4" />
            <span>Upload Image</span>
          </label>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-3">
        {imagesLoading ? (
          <p>Loading images...</p>
        ) : (
          images.map((imageId) => (
            <ImageTile 
              key={imageId} 
              imageId={imageId} 
              isSelected={backgroundImage === imageId}
              onClick={() => handleBackgroundSelect(imageId)}
            />
          ))
        )}
      </div>
      {images.length === 0 && (
        <p className="text-gray-500 italic text-center mt-4">No background images available. Upload an image to get started.</p>
      )}
    </div>
  );
};

export default BackgroundsTab;