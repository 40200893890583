import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import config from '../config';

const VideoPlayer = ({ user, videoId, isPaused }) => {
  const vidRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState('');
  useEffect(() => {
    if (vidRef.current) {
      if (isPaused) {
        vidRef.current.pause();
      } else {
        vidRef.current.play();
      }
    }
  }, [isPaused]);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const idToken = await user.getIdToken();
        const response = await axios.get(`${config.API_BASE_URL}/stream-video/${videoId}`, {
          headers: { 'Authorization': `Bearer ${idToken}` },
          responseType: 'blob'
        });
        const url = URL.createObjectURL(response.data);
        setVideoUrl(url);
      } catch (error) {
        console.error('Error fetching video:', error);
      }
    };

    if (user && videoId) {
      fetchVideo();
    } 
  }, [user, videoId]);

  return (
    <div className="w-full h-auto">
      {videoUrl ? (
        <video ref={vidRef} controls autoPlay={!isPaused} className="w-full h-auto max-h-[70vh]">
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div className="w-full h-0 pb-[56.25%] bg-gray-100 flex flex-col justify-center items-center relative">
          <div className="w-10 h-10 border-3 border-gray-300 border-t-blue-500 rounded-full animate-spin absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
          <p className="mt-16 text-gray-600 text-sm">Loading video...</p>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;