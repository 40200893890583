import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Edit, Globe, Image, Check, Rocket, Home, RefreshCw, Mic, Library, Wand2, RotateCcw } from 'lucide-react';
import config from '../config';
import axios from 'axios';
import VideoPlayer from './VideoPlayer';
import BackgroundsTab from './BackgroundsTab';
import VoicesTab from './VoicesTab';
import TranscriptTab from './TranscriptTab';
import { useNavigate } from 'react-router-dom';
import PublishButton from './PublishButton';

const imageCache = new Map();

const ProcessingStatus = ({ user, uploadedVideoId, initialProjectTitle, initialTranscript, resetState, openSidePanel }) => {
  const [transcript, setTranscript] = useState(initialTranscript);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [selectedTab, setSelectedTab] = useState('transcript');
  const [selectedLanguage, setSelectedLanguage] = useState(0);
  const [isTranslating, setIsTranslating] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState("en-US-Neural2-I");
  const [languages, setLanguages] = useState([]);
  const [voices, setVoices] = useState([]);
  const [images, setImages] = useState([]);
  const [dubbedVideoId, setDubbedVideoId] = useState(null);
  const [processingStatus, setProcessingStatus] = useState('Processing...');
  const [projectTitle, setProjectTitle] = useState(initialProjectTitle);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isConfigChanged, setIsConfigChanged] = useState(false);
  const lastProcessedConfig = useRef(null);
  const [publishingStatus, setPublishingStatus] = useState('idle');
  const navigate = useNavigate();
  const [imagesLoading, setImagesLoading] = useState(true);
  const [originalTranscript, setOriginalTranscript] = useState(initialTranscript);

  useEffect(() => {
    fetchLanguages();
    fetchVoices();
    fetchImages();
    handleVideoDub();
  }, []);

  const handleVoicePlay = async (voiceId, callback) => {
    try {
      const idToken = await user.getIdToken();
      const response = await axios.get(`${config.API_BASE_URL}/stream-voice/${voiceId}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'audio/wav'
        },
        responseType: 'blob'
      });
      const url = URL.createObjectURL(response.data);
      const audio = new Audio(url);
      audio.play();
      if (callback) callback(audio);
    } catch (error) {
      console.error('Error playing voice:', error);
    }
  };
  
  const fetchLanguages = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(`${config.API_BASE_URL}/languages`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        }
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setLanguages(data.languages);
    } catch (error) {
      console.error('Error fetching languages:', error);
    }
  };

  const fetchVoices = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(`${config.API_BASE_URL}/voices`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        }
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setVoices(data.voices);
    } catch (error) {
      console.error('Error fetching voices:', error);
    }
  };

  const fetchImages = useCallback(async () => {
    setImagesLoading(true);
    try {
      const idToken = await user.getIdToken();
      const response = await fetch(`${config.API_BASE_URL}/images`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        }
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      const fetchedImages = data.images || [];
      
      fetchedImages.forEach(imageId => {
        if (!imageCache.has(imageId)) {
          imageCache.set(imageId, null);
        }
      });

      setImages(fetchedImages);
      
      await Promise.all(fetchedImages.map(fetchImageData));
    } catch (error) {
      console.error('Error fetching images:', error);
      setImages([]);
    } finally {
      setImagesLoading(false);
    }
  }, [user]);

  const fetchImageData = async (imageId) => {
    if (imageCache.get(imageId) !== null) return;

    try {
      const idToken = await user.getIdToken();
      const response = await axios.get(`${config.API_BASE_URL}/image/${imageId}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`,
        },
        responseType: 'blob'
      });
      const url = URL.createObjectURL(response.data);
      imageCache.set(imageId, url);
    } catch (error) {
      console.error(`Error fetching image data for ID ${imageId}:`, error);
      imageCache.set(imageId, null);
    }
  };

  const handleVideoDub = async () => {
    try {
      const idToken = await user.getIdToken();
      const response = await axios.post(`${config.API_BASE_URL}/video-dub-async`, {
        video_id: uploadedVideoId,
        modifier: {
          language: { id: parseInt(selectedLanguage) },
          voice_id: selectedVoice,
          transcript: transcript.map((paragraph) => ({
            start: paragraph.start,
            end: paragraph.end,
            text: paragraph.text.trim(),
          })),
        },
        title: projectTitle,
        background_image_id: backgroundImage,
      }, {
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
      setDubbedVideoId(response.data.video_id);
      checkProcessingStatus(response.data.video_id);
    } catch (error) {
      console.error('Error dubbing video:', error);
    }
  };

  const checkProcessingStatus = async (videoId) => {
    try {
      const idToken = await user.getIdToken();
      const response = await axios.get(`${config.API_BASE_URL}/is-processed/${videoId}`, {
        headers: { 'Authorization': `Bearer ${idToken}` }
      });
      if (response.data.is_processed) {
        setProcessingStatus('Dubbing completed!');
      } else {
        setProcessingStatus('Processing...');
        setTimeout(() => {
          checkProcessingStatus(videoId);
        }, 5000);
      }
    } catch (error) {
      console.error('Error checking processing status:', error);
    }
  };

  const handleTitleChange = (e) => {
    setProjectTitle(e.target.value);
  };

  const handleTitleBlur = () => {
    setIsEditingTitle(false);
    if (projectTitle.trim() === '') {
      setProjectTitle('Give your project a name');
    }
  };

  const handleTitleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleTitleBlur();
    }
  };

  const checkConfigChanged = useCallback(() => {
    const currentConfig = {
      transcript: JSON.stringify(transcript),
      selectedLanguage,
      selectedVoice,
      backgroundImage,
      projectTitle
    };

    if (!lastProcessedConfig.current) {
      lastProcessedConfig.current = currentConfig;
      return false;
    }

    const isChanged = JSON.stringify(currentConfig) !== JSON.stringify(lastProcessedConfig.current);
    setIsConfigChanged(isChanged);
  }, [transcript, selectedLanguage, selectedVoice, backgroundImage, projectTitle]);

  useEffect(() => {
    checkConfigChanged();
  }, [checkConfigChanged]);

  const handleConfigChange = useCallback(() => {
    checkConfigChanged();
  }, [checkConfigChanged]);

  const handleUpdate = () => {
    setProcessingStatus('Processing...');
    handleVideoDub();
    lastProcessedConfig.current = {
      transcript: JSON.stringify(transcript),
      selectedLanguage,
      selectedVoice,
      backgroundImage,
      projectTitle
    };
    setIsConfigChanged(false);
  };

  const handleViewLibrary = () => {
    openSidePanel();
    navigate('/library');
  };

  return (
    <div className="flex h-full flex-col">
      <div className="pt-0 pb-6 flex items-center justify-between pt-4">
        <motion.button
          className="flex items-center space-x-2 text-indigo-600 hover:text-indigo-800 transition-colors duration-200 group text-base"
          onClick={resetState}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Home size={27} className="group-hover:rotate-12 transition-transform duration-200" />
          <span className="font-medium">Home</span>
        </motion.button>
        <div className="flex-grow flex items-center justify-center">
          {isEditingTitle ? (
            <input
              type="text"
              value={projectTitle}
              onChange={handleTitleChange}
              onBlur={handleTitleBlur}
              onKeyDown={handleTitleKeyDown}
              className="text-3xl font-bold bg-transparent border-b-2 border-indigo-300 focus:outline-none focus:border-indigo-500 text-indigo-800 text-center cursor-default hover:cursor-text"
              autoFocus
            />
          ) : (
            <div className="flex items-center">
              <h1
                className="text-3xl font-bold cursor-pointer text-indigo-800 text-center cursor-default hover:cursor-text"
                onClick={() => setIsEditingTitle(true)}
              >
                {projectTitle}
              </h1>
              {!isEditingTitle && (
                <button
                  className="ml-2 text-indigo-500 hover:text-indigo-700"
                  onClick={() => setIsEditingTitle(true)}
                >
                  ✏️
                </button>
              )}
            </div>
          )}
        </div>
        <div className="ml-4">
          <PublishButton
            user={user}
            uploadedVideoId={uploadedVideoId}
            selectedLanguage={selectedLanguage}
            selectedVoice={selectedVoice}
            transcript={transcript}
            projectTitle={projectTitle}
            backgroundImage={backgroundImage}
            setDubbedVideoId={setDubbedVideoId}
            publishingStatus={publishingStatus}
            setPublishingStatus={setPublishingStatus}
          />
        </div>
      </div>
      
      <div className="flex flex-grow h-full overflow-visible mb-8">
        <div className="w-2/3 bg-white rounded-2xl shadow-lg overflow-visible flex flex-col mr-4">
          <div className="flex justify-between items-center p-3 border-b border-gray-200">
            <h3 className="text-2xl font-semibold">Live Preview</h3>
          </div>
          <div className="flex-grow relative overflow-hidden">
            {processingStatus === 'Processing...' ? (
              <div className="absolute inset-0 flex flex-col items-center justify-center bg-gradient-to-br from-indigo-100 to-purple-100">
                <motion.div 
                  className="text-6xl mb-4"
                  animate={{ rotate: 360 }}
                  transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                >
                  <Wand2 className="text-indigo-600" />
                </motion.div>
                <p className="text-2xl font-semibold text-indigo-700 mb-2">Processing...</p>              
                <div className="mt-6">
                  {[0, 1, 2].map((i) => (
                    <motion.div 
                      key={i}
                      className="inline-block w-3 h-3 mx-1 bg-indigo-500 rounded-full"
                      animate={{ 
                        y: [0, -10, 0],
                        opacity: [0.5, 1, 0.5],
                        scale: [1, 1.2, 1]
                      }}
                      transition={{ 
                        duration: 1.5, 
                        repeat: Infinity, 
                        delay: i * 0.2 
                      }}
                    />
                  ))}
                </div>
              </div>
            ) : (
              dubbedVideoId ? (
                <div className="h-full flex items-center justify-center p-4 relative">
                  <VideoPlayer 
                    key={dubbedVideoId}
                    user={user} 
                    videoId={dubbedVideoId} 
                    className={`rounded-lg shadow-lg max-w-full max-h-full object-contain ${isConfigChanged ? 'filter blur-sm' : ''}`}
                    isPaused={isConfigChanged}
                  />
                  <AnimatePresence>
                    {isConfigChanged && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-60"
                      >
                        <motion.button
                          className="flex items-center space-x-2 bg-gradient-to-r from-purple-600 to-indigo-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg mb-4"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={handleUpdate}
                        >
                          <RefreshCw size={24} className="mr-2" />
                          <span className="text-lg">Update Preview</span>
                        </motion.button>
                        <motion.button
                          className="flex items-center space-x-2 bg-gradient-to-r from-red-600 to-pink-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-lg mb-4"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => {
                            setTranscript(JSON.parse(lastProcessedConfig.current.transcript));
                            setSelectedLanguage(lastProcessedConfig.current.selectedLanguage);
                            setSelectedVoice(lastProcessedConfig.current.selectedVoice);
                            setBackgroundImage(lastProcessedConfig.current.backgroundImage);
                            setProjectTitle(lastProcessedConfig.current.projectTitle);
                            setIsConfigChanged(false);
                          }}
                        >
                          <RotateCcw size={24} className="mr-2" />
                          <span className="text-lg">Reset</span>
                        </motion.button>
                        <motion.p
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.2 }}
                          className="text-white text-sm font-medium max-w-xs text-center"
                        >
                          Changes detected. Update to see them in action.
                        </motion.p>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ) : (
                <div className="absolute inset-0 flex items-center justify-center bg-gray-100 text-gray-500">
                  Video will be displayed here once processing is complete
                </div>
              )
            )}
          </div>
        </div>
        <div className="w-1/3 bg-white rounded-2xl shadow-lg overflow-visible flex flex-col">
          <div className="flex justify-between items-center p-3 border-b border-gray-200">
            <h3 className="text-2xl font-semibold">Customize</h3>
          </div>
          <div className="flex flex-col flex-grow overflow-hidden">
            <div className="flex border-b border-gray-200">
              <TabButton icon={Edit} label="Transcript" isActive={selectedTab === 'transcript'} onClick={() => setSelectedTab('transcript')} />
              <TabButton icon={Mic} label="Voices" isActive={selectedTab === 'voices'} onClick={() => setSelectedTab('voices')} />
              <TabButton icon={Image} label="Backgrounds" isActive={selectedTab === 'backgrounds'} onClick={() => setSelectedTab('backgrounds')} />
            </div>
            <div className="flex-grow overflow-visible">
              {selectedTab === 'transcript' && (
                <TranscriptTab 
                  user={user}
                  transcript={transcript}
                  setTranscript={setTranscript}
                  isTranslating={isTranslating}
                  setIsTranslating={setIsTranslating}
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  languages={languages}
                  uploadedVideoId={uploadedVideoId}
                  onConfigChange={handleConfigChange}
                  originalTranscript={originalTranscript}                  
                />
              )}
              {selectedTab === 'voices' && (
                <VoicesTab 
                  voices={voices}
                  selectedVoice={selectedVoice}
                  setSelectedVoice={setSelectedVoice}
                  handleVoicePlay={handleVoicePlay}
                  onConfigChange={handleConfigChange}
                />
              )}
              {selectedTab === 'backgrounds' && (
                <BackgroundsTab 
                  user={user}
                  setBackgroundImage={setBackgroundImage}
                  backgroundImage={backgroundImage}
                  images={images}
                  setImages={setImages}
                  fetchImages={fetchImages}
                  onConfigChange={handleConfigChange}
                  imageCache={imageCache}
                  imagesLoading={imagesLoading}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {publishingStatus === 'completed' && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
            className="fixed top-4 right-4 left-4 md:left-auto md:w-auto bg-white rounded-lg shadow-lg p-4 flex items-center justify-between z-50"
          >
            <div className="flex items-center space-x-2">
              <Check className="text-green-500 flex-shrink-0" size={20} />
              <span className="text-gray-800 font-medium">
                Your video is now available in the Library!
              </span>
            </div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="ml-4 bg-indigo-100 text-indigo-600 px-3 py-1 rounded-md text-sm font-medium flex items-center"
              onClick={handleViewLibrary}
            >
              <Library size={16} className="mr-1" />
              View Library
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

const TabButton = ({ icon: Icon, label, isActive, onClick }) => (
  <motion.button
    className={`flex items-center space-x-2 px-4 py-3 text-sm font-medium ${
      isActive ? 'text-indigo-600 border-b-2 border-indigo-600' : 'text-gray-500 hover:text-gray-700'
    }`}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    onClick={onClick}
  >
    <Icon size={18} />
    <span>{label}</span>
  </motion.button>
);

export default ProcessingStatus;