import React, { useState, useEffect } from 'react';
import { Play, Pause, Globe } from 'lucide-react';

const VoicesTab = ({ voices, selectedVoice, setSelectedVoice, handleVoicePlay, onConfigChange }) => {
  const [selectedAccent, setSelectedAccent] = useState('');
  const [playingVoice, setPlayingVoice] = useState(null);
  const [audioRef, setAudioRef] = useState(null);
  const accents = [...new Set(voices.map(voice => voice.accent))];

  useEffect(() => {
    if (selectedVoice) {
      const selectedVoiceData = voices.find(voice => voice.id === selectedVoice);
      if (selectedVoiceData) {
        setSelectedAccent(selectedVoiceData.accent);
      }
    } else if (!selectedAccent && accents.length > 0) {
      setSelectedAccent(accents[0]);
    }
  }, []);

  const filteredVoices = selectedAccent
    ? voices.filter(voice => voice.accent === selectedAccent)
    : voices;

  const handlePlay = (voiceId) => {
    if (playingVoice === voiceId) {
      // Stop the currently playing audio
      if (audioRef) {
        audioRef.pause();
        audioRef.currentTime = 0;
      }
      setPlayingVoice(null);
      setAudioRef(null);
    } else {
      // Stop any currently playing audio
      if (audioRef) {
        audioRef.pause();
        audioRef.currentTime = 0;
      }
      // Play the new voice
      setPlayingVoice(voiceId);
      handleVoicePlay(voiceId, (newAudioRef) => {
        setAudioRef(newAudioRef);
        newAudioRef.onended = () => {
          setPlayingVoice(null);
          setAudioRef(null);
        };
      });
    }
  };

  const handleVoiceSelect = (voiceId) => {
    setSelectedVoice(voiceId);
    onConfigChange();
  };

  const handleAccentChange = (accent) => {
    setSelectedAccent(accent);
  };

  return (
    <div className="flex flex-col h-full rounded-lg">
      <div className="mb-4 flex items-center px-6 py-4">
        <label htmlFor="accent-select" className="block text-sm font-medium text-gray-700 mr-2">                    
        <Globe size={16} className="inline-block ml-1 align-text-bottom" /> Select Accent 
        </label>
        <select
          id="accent-select"
          className="flex-grow bg-white text-gray-800 text-sm py-2 px-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 transition duration-300 ease-in-out shadow-sm"
          value={selectedAccent}
          onChange={(e) => handleAccentChange(e.target.value)}
        >
          {accents.map(accent => (
            <option key={accent} value={accent} className="py-2 px-3 hover:bg-indigo-100 transition duration-200 ease-in-out">
              {accent}
            </option>
          ))}
        </select>
      </div>

      <div className="flex-grow overflow-auto" style={{ maxHeight: 'calc(100vh - 400px)' }}>
        <div className="grid grid-cols-2 gap-4 px-4">
          {filteredVoices.map(voice => (
            <div
              key={voice.id}
              className={`p-4 rounded-lg cursor-pointer transition-all duration-200 relative ${
                selectedVoice === voice.id
                  ? 'bg-indigo-100 border-2 border-indigo-500'
                  : 'bg-gray-100 hover:bg-gray-200'
              }`}
              onClick={() => handleVoiceSelect(voice.id)}
            >
              <div className="flex justify-between items-center">
                <span className="font-medium">
                  {voice.person_name} ({voice.gender === 1 ? 'Male' : 'Female'})
                </span>
              </div>
              <div className="text-sm text-gray-600">{voice.language}</div>
              <button
                className="absolute top-1/2 right-2 transform -translate-y-1/2 w-8 h-8 bg-gradient-to-r from-indigo-500 to-indigo-700 text-white rounded-full flex items-center justify-center hover:from-indigo-600 hover:to-indigo-800 transition-all duration-200 shadow-md overflow-hidden group"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePlay(voice.id);
                }}
                title={playingVoice === voice.id ? "Pause" : "Play"}
              >
                <div className="absolute inset-0 bg-white opacity-25 group-hover:opacity-0 transition-opacity duration-200"></div>
                <div className="relative z-10">
                  {playingVoice === voice.id ? <Pause size={14} /> : <Play size={14} />}
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VoicesTab;